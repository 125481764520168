import { vars } from '@seed-design/design-token';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import CircularProgress from '../CircularProgress';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  children: ReactNode | string;
  type?: 'button' | 'submit' | 'reset';
  fixed?: boolean;
  bottom?: number;
  theme?: 'primary' | 'secondary' | 'tertiary';
  customTextColor?: string;
}

export const ContainedButton = ({
  children,
  size = 'medium',
  isLoading = false,
  isDisabled = false,
  type = 'button',
  fixed = false,
  bottom,
  theme = 'primary',
  customTextColor,
  ...props
}: Props) => (
  <Button
    size={size}
    isLoading={isLoading}
    disabled={isDisabled}
    isDisabled={isDisabled}
    type={type}
    fixed={fixed}
    bottom={bottom}
    theme={theme}
    customTextColor={customTextColor}
    {...props}
  >
    {isLoading ? (
      <CircularProgress color={vars.$semantic.color.paperDefault} size={16} />
    ) : (
      <Text>{children}</Text>
    )}
  </Button>
);

const Button = styled.button<
  Pick<
    Props,
    'size' | 'isDisabled' | 'isLoading' | 'fixed' | 'bottom' | 'theme' | 'customTextColor'
  >
>(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    letterSpacing: '-0.02em',
    fontWeight: 'bold',
    opacity: 1,
  },

  ({ isDisabled, isLoading }) => ({
    background: isDisabled ? vars.$scale.color.gray300 : vars.$scale.color.carrot500,
    color: isDisabled ? vars.$scale.color.gray500 : vars.$semantic.color.onPrimary,

    '&:active': {
      opacity: isDisabled || isLoading ? 1 : 0.8,
    },
  }),
  ({ isDisabled, theme }) => {
    if (theme === 'primary') {
      return {
        background: isDisabled ? vars.$scale.color.gray300 : vars.$scale.color.carrot500,
        color: isDisabled ? vars.$scale.color.gray500 : vars.$semantic.color.onPrimary,
      };
    }
    if (theme === 'secondary') {
      return {
        background: isDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray300,
        color: isDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray900,
      };
    }

    return {
      background: isDisabled ? vars.$scale.color.gray500 : vars.$semantic.color.primaryLow,
      color: isDisabled ? vars.$scale.color.gray500 : vars.$scale.color.carrot500,
    };
  },
  ({ bottom }) => ({
    bottom: bottom === 0 ? `0.5rem` : `calc(${bottom}px + 1rem)`,
  }),

  ({ fixed, bottom }) => ({
    position: fixed ? 'fixed' : 'static',
    left: fixed ? '1rem' : 0,
    width: fixed ? `calc(100% - 2rem)` : `100%`,
    bottom:
      bottom === 0 ? `calc(env(safe-area-inset-bottom) + 0.5rem)` : `calc(${bottom}px + 1rem)`,
  }),

  ({ customTextColor }) => {
    if (customTextColor) {
      return {
        color: customTextColor,
      };
    }
  },

  ({ size }) => {
    switch (size) {
      case 'large': {
        return {
          height: '3rem',
          fontSize: '1.125rem',
          padding: '0 1.25rem',
          borderRadius: '0.375rem',
          lineHeight: '1.5rem',
          fontWeight: 'bold',
          letterSpacing: '-0.02em',
        };
      }
      case 'medium': {
        return {
          height: '2.5rem',
          fontSize: '1rem',
          padding: '0 1rem',
          borderRadius: '0.3125rem',
          lineHeight: '1.375rem',
          fontWeight: 'bold',
          letterSpacing: '-0.02em',
        };
      }
      case 'small':
      default: {
        return {
          height: '2.25rem',
          fontSize: '0.875rem',
          padding: '0 0.875rem',
          borderRadius: '0.3125rem',
          lineHeight: '1.25rem',
          fontWeight: 'bold',
          letterSpacing: '-0.02em',
        };
      }
    }
  }
);

const Text = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
`;
