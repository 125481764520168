import * as React from 'react';
import { HeadFC } from 'gatsby';
import styled from 'styled-components';
import { vars } from '@seed-design/design-token';
import { ContainedButton } from '@components/common/Button';
import { bridge } from '@utils/bridge/index';

const NotFoundPage = () => {
  const handleBack = () => {
    bridge?.closeRouter({});
  };

  return (
    <Wrpper>
      <h1>
        앗! 죄송해요.
        <br />
        해당 페이지로 접근이 불가능해요.
      </h1>

      <ContainedButton size="small" style={{ width: '200px' }} onClick={handleBack}>
        뒤로가기
      </ContainedButton>
    </Wrpper>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Not found</title>;

const Wrpper = styled.main`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${vars.$scale.color.gray600};

    margin-bottom: 20px;
  }
`;
